import { Skeleton } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";

function CardBeritaSkelton() {
    return (
        <Card className="shadow" style={{ width: '100%', fontSize: '10pt' }}>
            <Skeleton variant="rounded" height={150} width={'100%'} />
            <Card.Body className="text-start" style={{ height: '120px' }}>
                {/* body  */}
                <Skeleton variant="rounded" width={'90%'} height={30} />
                <br />
                <Skeleton variant="rounded" width={'90%'} height={30} />
            </Card.Body>
            <Card.Footer>
                {/* footer  */}
                <Row>
                    <Col lg={2}>
                        <Skeleton variant="rounded" width={30} height={30} />
                    </Col>
                    <Col>
                        <Skeleton variant="rounded" width={30} height={30} />
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
}

export default CardBeritaSkelton;
