import { Skeleton } from "@mui/material";
import { useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";

function CardDetailSkelton() {
    const [datas, setDatas] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    return (
        <Card className="shadow">
            <Card.Body className="h-100 text-start">
                <Skeleton variant="rounded" width={100} height={30} />
                <hr />
                <Skeleton variant="rounded" width={300} height={30} />
                <Table>
                    <tbody>
                        {
                            datas.map((item, index) => (
                                <tr key={index}>
                                    <td style={{width:'15%'}}>
                                        <Skeleton variant="rounded" height={30} />
                                    </td>
                                    <td>
                                        <Skeleton variant="rounded" height={30} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default CardDetailSkelton;