import { Card, Col, Container, Image, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import { Link, useLocation, useParams } from "react-router-dom";
import Utils from "../../utils/Utils";
import { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function InfografisDetail() {
    let { id } = useParams();
    const [datas, setDatas] = useState();
    const [state, setState] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");

    const getData = () => {
        fetch(`${Utils.urlDev}Infografis/Detail/${id}`)
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                setDatas(json.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData();
    }, [id])

    setTimeout(() => {
        setState(true)
    }, 1000);

    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Infografis Detail" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={7} className="text-center">
                            {datas && (
                                <>
                                    <div className="text-start" style={{ color: '#464646' }}>{datas.created_date}</div>
                                    <h3 className="text-start" style={{ fontWeight: '600', color: '#464646' }}>{datas.judul}</h3>
                                </>
                            )}

                            {
                                state && (
                                    <OwlCarousel
                                        className='owl-theme'
                                        autoplay
                                        autoplayTimeout={5000}
                                        loop
                                        margin={10}
                                        center
                                        smartSpeed={1000}
                                        responsiveClass
                                        responsive={{
                                            0: {
                                                items: 1
                                            },
                                            600: {
                                                items: 1
                                            },
                                            1000: {
                                                items: 1
                                            }
                                        }}>
                                        {
                                            datas.photo.map((item, index) => (
                                                <Image key={index} src={`${Utils.urlStorageLocal}${item.photo_path}`} />
                                            ))
                                        }
                                    </OwlCarousel>
                                )
                            }

                            {datas && (
                                <Card className="shadow">
                                    <Card.Body>
                                        <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>
                                            {datas.isi}
                                        </pre>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                    </Row>


                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <hr />
                            <h3 className="mb-4">Infografis Lainnya</h3>
                            <Row className="row-cols-1 row-cols-md-3 g-4 mb-3">
                                {datas && (
                                    datas.infografis_lain.map((item, index) => (
                                        <Col key={index}>
                                            <Link className="text-decoration-none" to={`/${language[1]}/Infografis/Detail/${item.id}`}>
                                                <Card className="shadow" style={{ width: '100%' }}>
                                                    <div style={{ overflow: 'hidden' }}>
                                                        <Card.Img className="image-hover-zoom" variant="top" src={`${Utils.urlStorageLocal}${item.photo_path}`} />
                                                    </div>
                                                    <Card.Body style={{ minHeight: '100px' }}>
                                                        <Card.Text className="text-start" style={{ color: '#464646', fontWeight: '600' }}>
                                                            {item.judul}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        </Col>
                                    ))
                                )}

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default InfografisDetail;