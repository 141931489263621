import { Button, Col, Container, Form, Image, Modal, Nav, ProgressBar, Row } from "react-bootstrap";
import Divider from "./Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import { useState } from "react";

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Survey Pengunjung
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ background: '#eee' }}>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nama</Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Komentar / Saran</Form.Label>
                        <Form.Control as="textarea" placeholder="Enter Saran" style={{ height: '100px' }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Rating</Form.Label>
                        <Form.Select>
                            <option>-- Pilih Surver --</option>
                            <option>Sangat Bagus</option>
                            <option>Bagus</option>
                            <option>Cukup</option>
                            <option>Kurang</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Simpan</Button>
            </Modal.Footer>
        </Modal>
    );
}

function HomeSurvey() {
    const [modalShow, setModalShow] = useState(false);

    const handleSurvey = () => {
        setModalShow(true)
    }

    return (
        <section className="mt-5">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <h3>Survey Kepuasan Pengunjung</h3>
                            <Divider width={10} />
                            <div>Mohon kesediaan Anda untuk memberikan penilaian dan masukan kepada kami, dimana hal ini sangat bermanfaat untuk meningkatkan kualitas pelayanan kami. Silahkan diisi dengan memilih pilihan jenisnya, serta mengisi alamat email pada kolom yang telah di sediakan, setelah itu klik simpan</div>
                            <Button className="btn-theme mt-3" onClick={handleSurvey}>Ikuti Survey</Button>
                        </Col>
                        <Col md={6}>
                            <h3 className="progress-bar-title">Hasil Penilaian</h3>
                            <Divider width={10} />
                            <Row>
                                <Col md={6}>
                                    <div className="position-relative shadow circle" style={{ margin: '0px auto' }}>
                                        <div className="position-absolute shadow circle-inner-1"></div>
                                        <div className="position-absolute shadow circle-inner-2">
                                            <h1 style={{ fontWeight: '600', fontSize: '40pt', textAlign: 'center', marginTop: '10px' }}>4.9</h1>
                                            <Nav className="justify-content-center">
                                                <Nav.Item className="p-1"><FontAwesomeIcon icon={faStar} className="text-warning" /></Nav.Item>
                                                <Nav.Item className="p-1"><FontAwesomeIcon icon={faStar} className="text-warning" /></Nav.Item>
                                                <Nav.Item className="p-1"><FontAwesomeIcon icon={faStar} className="text-warning" /></Nav.Item>
                                                <Nav.Item className="p-1"><FontAwesomeIcon icon={faStar} className="text-warning" /></Nav.Item>
                                                <Nav.Item className="p-1"><FontAwesomeIcon icon={faStar} className="text-secondary" /></Nav.Item>
                                            </Nav>
                                            <div className="text-center">44 Penilaian</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="progress-bar-inner">
                                        <Progress nilai="5" percent="60" />
                                        <Progress nilai="4" percent="20" />
                                        <Progress nilai="3" percent="10" />
                                        <Progress nilai="2" percent="10" />
                                        <Progress nilai="1" percent="0" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

function Progress(props) {
    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: '5px 0 5px 0' }}>
            <div style={{ width: '30px' }}>{props.nilai}</div>
            <div style={{ width: '100%' }}>
                <ProgressBar now={props.percent} max={100} className="shadow-sm" />
            </div>
        </div>
    );
}

export default HomeSurvey;