import { Button, Card, Col, Container, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Utils from "../../../utils/Utils";
import CardBeritaSkelton from "../../../components/CardBeritaSkelton";

function BeritaDetail() {
    const { id, lang } = useParams();
    const [datas, setDatas] = useState([]);
    const [dataBerita, setDataBerita] = useState([]);
    const [show, setShow] = useState(false)

    const getData = () => {
        fetch(`${Utils.url}berita/${id}`)
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                setDatas(json.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBerita = () => {
        fetch(`${Utils.url}berita`)
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                setDataBerita(json.data.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        setShow(true);
        setDatas([]);
        setDataBerita([]);
        setTimeout(() => {
            getData();
            getBerita();
            setShow(false)
        }, 2000);
    }, [id, lang])

    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Berita Detail" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={8} className="text-center">
                            {
                                show ? <CardBeritaSkelton /> : <Detail datas={datas} />
                            }
                        </Col>
                    </Row>

                    {/* berita lain */}
                    {
                        !show && (
                            <Row className="mt-5 align-item-center justify-content-center h-100">
                                <Col lg={10} className="text-center">
                                    <hr />
                                    <h3>Berita Lain</h3>
                                    <Row className="row-cols-1 row-cols-md-3 g-4 mb-3" style={{ paddingTop: '10px' }}>
                                        {dataBerita.slice(0, 3).map((item, index) => (
                                            <Col key={index}>
                                                <Card className="shadow" style={{ width: '100%', fontSize: '10pt' }}>
                                                    <div style={{ height: '150px', width: '100%', position: 'relative' }}>
                                                        <Card.Img
                                                            variant="top"
                                                            src={`${Utils.urlStorage}/${item.gambar_path}`}
                                                            style={{
                                                                height: '100%',
                                                                width: '100%',
                                                                position: 'absolute',
                                                                objectFit: 'cover',
                                                                left: '0'
                                                            }}
                                                        />
                                                    </div>
                                                    <Card.Body style={{ height: '120px' }}>
                                                        <Card.Text className="text-start" style={{ fontWeight: '600', color: '#464646' }}>
                                                            {item.judul}
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <Row>
                                                            <Col>
                                                                <div className="pt-2 text-start">
                                                                    {item.tanggal}
                                                                </div>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button as={Link} to={`/${lang}/Berita/Detail/${item.judul_}`} variant="outline-info" style={{ fontSize: '10pt' }}>Detail</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </section >
        </>
    );
}

function Detail({ datas }) {
    return (
        <Card className="shadow" style={{ width: '100%', fontSize: '10pt' }}>
            <div style={{ height: '350px', width: '100%', position: 'relative' }}>
                <Card.Img
                    variant="top"
                    src={`${Utils.urlStorage}/${datas.gambar_path}`}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        objectFit: 'cover',
                        left: '0'
                    }}
                />
            </div>
            <Card.Body className="text-start" >
                <Card.Text style={{ fontWeight: '600' }}>
                    <div style={{ fontSize: '15pt' }}>{datas.judul}</div>
                    <div style={{ fontWeight: '400' }}>{datas.tanggal}</div>
                    <hr />
                    <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#464646' }}>
                        {datas.isi}
                    </pre>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default BeritaDetail;