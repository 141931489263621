import { faDownload, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Form, Pagination, Row, } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import PeraturanSearch from "./PeraturanSearch";
import { useEffect, useState } from "react";
import Utils from "../../../utils/Utils";
import { useDispatch, connect } from "react-redux";
import CardItemSkelton from "../../../components/CardItemSkelton";

function PeraturanHome(props) {
    const [datas, setDatas] = useState([]);
    const [total, setTotal] = useState(0);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalSkelton, setTotalSkelton] = useState([1, 2, 3, 4, 5, 6, 7]);

    const handleNavigate = (val) => {
        navigate(`/id/Peraturan/Detail/${val}`)
    }

    const handleSearch = (data) => {
        setPage(1)
        dispatch({
            type: "SET_SEARCH",
            data: data
        });
        navigate(`/in/Peraturan/Search`)
    }

    const getData = () => {
        setDatas([])
        setTotal(0)
        fetch(`${Utils.url}peraturan/getPeraturan?page=${page}`)
            .then((response) => response.json())
            .then((json) => {
                console.log(json.data.data);

                setTimeout(() => {
                    setDatas(json.data.data)
                    setTotal(json.data.total)
                    setFrom(json.data.from)
                    setTo(json.data.last_page)
                }, 2000);

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [searchParam.get("page")])

    const handlePageChanges = (state) => {
        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }

    return (
        <>
            <section style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Peraturan" />
                <Container>
                    {/* pencarian document  */}
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <PeraturanSearch
                                handleSearch={handleSearch}
                                jenisPer={props.jenisPeraturan}
                                singkatanJen={props.singkatanJenis}
                                statusPer={props.statusPeraturan}
                            />
                        </Col>
                    </Row>

                    {/* card item  */}
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {
                                total === 0 ?
                                    totalSkelton.map((item, index) => (
                                        <CardItemSkelton key={index} />
                                    ))
                                    :
                                    datas.map((item, index) => (
                                        <div key={index} className="mb-4">
                                            <CardItem dataPeraturan={item} onNavigate={handleNavigate} />
                                        </div>
                                    ))
                            }
                            {/* {
                                datas ?
                                    datas.map((item, index) => (
                                        <div key={index} className="mb-4">
                                            <CardItem dataPeraturan={item} onNavigate={handleNavigate} />
                                        </div>
                                    ))
                                    :
                                    totalSkelton.map((item, index) => (
                                        <CardItemSkelton key={index} />
                                    ))
                            } */}
                        </Col>
                    </Row>

                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div>Total Item : {total}</div>
                            <div>Halaman ke {page} dari {to}</div>
                        </Col>
                    </Row>

                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <div className="d-flex justify-content-center">
                                <Paging
                                    handlePageChange={handlePageChanges}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

function CardItem({ dataPeraturan, onNavigate }) {

    const onNav = (e, val) => {
        e.preventDefault()
        onNavigate(val)
    }

    return (
        <Link className="text-decoration-none hvr-float-shadow w-100" onClick={(e) => onNav(e, dataPeraturan.request_no)} >
            <Card className="shadow-sm w-100">
                <Card.Header>
                    <Row>
                        <Col className="h-100 text-start">
                            <h5>{dataPeraturan.singkatan_jenis}</h5>
                        </Col>
                        <Col className="h-100 text-end">
                            <Button variant="success" className="btn-sm">Berlaku</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="h-100 text-start">
                    <div className="d-flex bd-highlight mb-3">
                        <div className="pe-3 bd-highlight widget-head">
                            <div className="text-center rounded widget">
                                <div style={{ padding: '10px' }}>
                                    <FontAwesomeIcon icon={faFilePdf} style={{ color: '#e6e6e6', fontSize: '20pt' }} />
                                </div>
                            </div>
                        </div>
                        <div className="bd-highlight">
                            <div className="mb-2" style={{ fontWeight: '600' }}>{dataPeraturan.jenis_peraturan} Nomor {dataPeraturan.nomor} Tahun {dataPeraturan.tahun_terbit}</div>
                            <div style={{ color: '#5c5c5c' }}>
                                {dataPeraturan.judul}
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col className="h-100 text-start">
                            <div className="d-flex bd-highlight">
                                <div className="pe-3 bd-highlight">
                                    <Button variant="outline-info" className="btn-sm">
                                        <FontAwesomeIcon icon={faDownload} />
                                        &nbsp;
                                        {dataPeraturan.nomor}
                                    </Button>
                                </div>
                                <div className="pe-3 bd-highlight">
                                    <Button variant="outline-info" className="btn-sm">
                                        <FontAwesomeIcon icon={faEye} />
                                        &nbsp;
                                        {dataPeraturan.view}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col className="h-100 text-end">
                            <Button variant="info" className="btn-sm text-white">
                                <FontAwesomeIcon icon={faDownload} />
                                &nbsp;
                                Unduh
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Link >
    );
}

function Paging({ handlePageChange }) {

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
        jenisPeraturan: state.jenisPeraturan,
        singkatanJenis: state.singkatanJenis,
        statusPeraturan: state.statusPeraturan
    }
}

export default connect(mapStateToProps)(PeraturanHome);