import { Col, Container, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import Utils from "../../../utils/Utils";
import { connect } from "react-redux";
import Divider from "../../../components/Divider";

function Sop(props) {
    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="SOP" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-start">
                            <div style={{ fontWeight: '600', color: '#464646' }}>
                                JDIH Badan Nasional Pengelola Perbatan RI
                            </div>
                            <Divider width={20} />
                        </Col>
                    </Row>
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <object data={`${Utils.urlStorage}storage/dokumen/sop_file.pdf`}
                                style={{ width: '100%', height: '700px' }}>
                            </object>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        sop: state.sop,
        struktur: state.struktur
    }
}

export default connect(mapStateToProps)(Sop);