import { Button, Card, Col, Container, Pagination, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Utils from "../../../utils/Utils";
import CardBeritaSkleton from "../../../components/CardBeritaSkelton";
import { Skeleton } from "@mui/material";

function Galery(props) {
    const [datas, setDatas] = useState();
    const [total, setTotal] = useState(0);
    const [totals, setTotals] = useState([1, 2, 3, 4, 5, 6, 7]);
    const { lang } = useParams();
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getData = (data) => {
        setTotal(0);
        setDatas([]);
        fetch(`${Utils.urlDev}Agenda?page=${page}`, {
            method: "POST",
            body: JSON.stringify({ search: data }),
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setTimeout(() => {
                    setDatas(json.data.data);
                    setTotal(json.data.total);
                    setFrom(json.data.from);
                    setTo(json.data.last_page);
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData(props.search)
    }, [props.search, searchParam.get("page")])

    const handlePageChanges = (state) => {
        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }

    return (
        <section style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Galery" />
            <Container>
                <Row className="mt-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <Row className="row-cols-1 row-cols-md-4 g-4 mb-3">
                            {
                                total === 0 ?
                                    totals.map((item, index) => (
                                        <Col key={index}>
                                            <CardBeritaSkleton />
                                        </Col>
                                    ))
                                    :
                                    datas.map((item, index) => (
                                        <Col key={index}>
                                            <CardItem datas={item} lang={lang} />
                                        </Col>
                                    ))
                            }
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-3 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <div>Total Item : {total}</div>
                        <div>Halaman ke {page} dari {to}</div>
                    </Col>
                </Row>

                {/* Pagination */}
                <Row className="mt-1 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <div className="d-flex justify-content-center">
                            <Paging
                                handlePageChange={handlePageChanges}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

function CardItem({ datas, lang }) {
    return (
        <Card className="shadow" style={{ width: '100%', fontSize: '10pt' }}>
            <div style={{ height: '150px', width: '100%', position: 'relative' }}>
                {
                    datas.gambar_path ? <Card.Img
                        variant="top"
                        src={`${Utils.urlStorageLocal}/${datas.gambar_path}`}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            objectFit: 'cover',
                            left: '0'
                        }}
                    />
                        : <>
                            <Skeleton variant="rounded" width={'100%'} height={'100%'} />
                        </>
                }
            </div>
            <Card.Body className="text-start" style={{ height: '120px' }}>
                <Card.Text>
                    {datas.judul}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col className="text-start">
                        <div style={{ color: '#464646', fontSize: '10pt' }}>
                            {datas.tanggal_awal}
                        </div>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
}

function Paging({ handlePageChange }) {

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

const mapStateToProps = (state) => {
    return {
        search: state.search
    }
}

export default connect(mapStateToProps)(Galery);