import { Col, Container, Row, Form, Button } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function Kontak(props) {
    return (
        <section style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Kontak" />
            <Container>
                {/* pencarian document  */}
                <Row className="mt-5 pb-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <div className="bg-light p-3">
                            <Row>
                                <iframe
                                    width="100%"
                                    height="350"
                                    id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=bnpp%20ri&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                    frameBorder="0"
                                ></iframe>
                            </Row>
                            <Row className="pt-5">
                                <Col lg={6}>
                                    <h5>Alamat</h5>
                                    <hr />
                                    <div className="text-start">
                                        <p>{props.alamat}</p>
                                        <div><FontAwesomeIcon icon={faPhone} />&nbsp; Phone : {props.telpon}</div>
                                        <div><FontAwesomeIcon icon={faEnvelope} />&nbsp; email : {props.email}</div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <h5 className="col-right">Kritik & Saran</h5>
                                    <hr />
                                    <Form>
                                        <div>
                                            <div className="text-start">Nama</div>
                                            <Form.Control
                                                placeholder="Masukan Nama"
                                                className="mb-3"
                                            />
                                        </div>

                                        <div>
                                            <div className="text-start">Email</div>
                                            <Form.Control
                                                placeholder="Masukan Alamat Email"
                                                className="mb-3"
                                            />
                                        </div>

                                        <div>
                                            <div className="text-start">No. HP</div>
                                            <Form.Control
                                                placeholder="Masukan No Hp"
                                                className="mb-3"
                                            />
                                        </div>
                                        <div>
                                            <div className="text-start">Masukan Instansi</div>
                                            <Form.Control
                                                placeholder="Masukan Instansi"
                                                className="mb-3"
                                            />
                                        </div>
                                        <div>
                                            <Button variant="primary w-100">Submit</Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Container>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        alamat: state.profile.alamat_lembaga,
        telpon: state.profile.telp_lembaga,
        email: state.profile.email_lembaga,
    }
}

export default connect(mapStateToProps)(Kontak);