import { Col, Container, Row } from "react-bootstrap";
import Divider from "./Divider";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title } from "chart.js/auto";
import ScrollAnimation from "react-animate-on-scroll";

function HomeStatistik({ dataStatistik }) {
    const data = {
        labels: [2022, 2023, 2024],
        datasets: [
            {
                label: "Berlaku",
                data: [10, 12, 15],
            },
            {
                label: "Merubah",
                data: [10, 12, 20],
            },
            {
                label: "Tidak Berlaku",
                data: [10, 12, 30],
            },
        ]
    }

    const [dataSt, setDataSt] = useState(data);
    const [options, setOptions] = useState({
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
    });

    useEffect(() => {
        let tahun = dataStatistik.tahun;
        let berlaku = dataStatistik.berlaku;
        let merubah = dataStatistik.merubah;
        let tidakBerlaku = dataStatistik.tidak_berlaku;
        let data = {
            labels: tahun,
            datasets: [
                {
                    label: "Berlaku",
                    data: berlaku,
                    backgroundColor: '#ef9a9a',
                    borderColor: '#ef5350',
                },
                {
                    label: "Merubah",
                    data: merubah,
                    backgroundColor: '#80cbc4',
                    borderColor: '#00695c'
                },
                {
                    label: "Tidak Berlaku",
                    data: tidakBerlaku,
                    backgroundColor: '#9fa8da',
                    borderColor: '#303f9f'
                },
            ]
        }
        setDataSt(data)
    }, [dataStatistik])

    return (
        <section className="mt-5 pt-5 pb-5" style={{ background: '#dedede' }}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Container>
                    <Row>
                        <Col>
                            <h3>Statistik Peraturan</h3>
                            <Divider width={10} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="offset-md-2">
                            <Bar data={dataSt} options={options} />
                        </Col>
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

export default HomeStatistik;