import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'hover.css/css/hover.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { act } from 'react-dom/test-utils';

// global stat
const initState = {
  lang: "id",
  menu: {
    beranda: 'Beranda',
    dokumenHukum: 'Dokumen Hukum',
    kegiatan: 'Kegiatan',
    informasiLain: 'Profil',
    lacakDokumen: 'Lacak Dokumen',
    kontak: 'Kontak',
  },
  tentangKami: "",
  visiMisi: "",
  statistik: {},
  landasanHukum: "",
  sop: "",
  struktur: {},
  anggota: [],
  search: {},
  searchBerita: {},
  jenisPeraturan: [],
  singkatanJenis: [],
  statusPeraturan: [],
  profile: {},
  media: [],
  translate: true,
}

// reducer 
const rootReducer = (state = initState, action) => {
  if (action.type === "SET_LANG") {
    if (action.lang === "en") {
      return {
        ...state,
        lang: action.lang,
        menu: {
          beranda: 'Home',
          dokumenHukum: 'Logal Document',
          kegiatan: 'Acitivity',
          informasiLain: 'Profile',
          lacakDokumen: 'Find Document',
          kontak: 'Contact',
        }
      }
    }

    if (action.lang === "id") {
      return {
        ...state,
        lang: action.lang,
        menu: {
          beranda: 'Beranda',
          dokumenHukum: 'Dokumen Hukum',
          kegiatan: 'Kegiatan',
          informasiLain: 'Profil',
          lacakDokumen: 'Lacak Dokumen',
          kontak: 'Kontak',
        }
      }
    }
  }

  if (action.type === "SET_TENTANGKAMI") {
    return {
      ...state,
      tentangKami: action.tentangKami,
      visiMisi: action.visiMisi,
      statistik: action.statistik,
      landasanHukum: action.landasanHukum,
      sop: action.sop,
      anggota: action.anggota,
      jenisPeraturan: action.jenisPeraturan,
      singkatanJenis: action.singkatanJenis,
      statusPeraturan: action.statusPeraturan,
      profile: action.profile,
      media: action.media,
      struktur: action.struktur
    }
  }

  if (action.type === "SET_SEARCH") {
    return {
      ...state,
      search: action.data
    }
  }

  if (action.type === "SET_TRANSLATE") {
    return {
      ...state,
      translate: false
    }
  }

  return state;
}

// store 
const store = createStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
