import { Col, Container, Pagination, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import PutusanSearch from "./PutusanSearch";

function PutusanHome() {
    const [datas, setDatas] = useState();
    const [total, setTotal] = useState(0);
    const { lang } = useParams();
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [active, setActive] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useSearchParams();
    const navigate = useNavigate();

    const handlePageChanges = (state) => {

        if (state === "next") {
            setPage(page + 1);
        }

        if (state === "prev") {
            setPage(page - 1);
        }

        if (state === "first") {
            setPage(1)
        }

        if (state === "last") {
            setPage(to)
        }

        console.log(state);

        navigate(`?page=${parseInt(page) + 1}`)
    }


    return (
        <section style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Putusan" />
            <Container>
                {/* pencarian document  */}
                <Row className="mt-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <PutusanSearch />
                    </Col>
                </Row>

                {/* card item  */}
                {/* <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            {datas && (
                                datas.map((item, index) => (
                                    <div key={index} className="mb-4">
                                        <CardItem dataArtikel={item} onNavigate={handleNavigate} />
                                    </div>
                                ))
                            )}
                        </Col>
                    </Row> */}

                <Row className="mt-3 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <div>Total Item : 0</div>
                        <div>Halaman ke 1 dari 1</div>
                    </Col>
                </Row>

                {/* Pagination */}
                <Row className="mt-1 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <div className="d-flex justify-content-center">
                            <Paging
                                handlePageChange={handlePageChanges}
                            />
                        </div>
                    </Col>
                </Row>


            </Container>
        </section>
    );
}

function Paging({ handlePageChange }) {

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange('first')} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} />
            <Pagination.Next onClick={() => handlePageChange('next')} />
            <Pagination.Last onClick={() => handlePageChange('last')} />
        </Pagination>
    );
}

export default PutusanHome;