import { Card, Col, Container, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from "react";
import FilterComponent from "../../components/FilterComponent";
import Utils from "../../utils/Utils";

function Glosarium() {
    const [datas, setDatas] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const handleButtonClick = (e, id) => {
        e.preventDefault();
        alert(id)
    }

    const columns = [
        {
            name: 'No',
            width: '8%',
            wrap: true,
            selector: row => row.nomor,
            sortable: true,
        },
        {
            name: 'Istilah',
            wrap: true,
            width: '20%',
            selector: row => row.istilah,
            sortable: true,
        },
        {
            name: 'Pengertian',
            wrap: true,
            selector: row => row.definisi,
            sortable: true,
        },
        {
            name: 'Peraturan Terkait',
            width: '250px',
            cell: (row) => (
                <a href={`/id/Peraturan/Detail/${row.peraturan_no}`}>{row.judul}</a>
            ),
            wrap: true,
        },
    ];

    const filteredItems = datas.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const getData = () => {
        fetch(`${Utils.urlDev}Glosarium`)
            .then((response) => response.json())
            .then(json => {
                console.log(json);
                setDatas(json.data)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <section style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Glosarium" />
            <Container>
                <Row className="mt-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <Card className="mb-5">
                            <Card.Body className="text-start">
                                <DataTable
                                    title="Glosarium"
                                    columns={columns}
                                    data={filteredItems}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    subHeader
                                    subHeaderComponent={subHeaderComponent}
                                    persistTableHead
                                    striped
                                    responsive
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Glosarium;