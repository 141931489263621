import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { connect, useDispatch } from "react-redux";
import '../assets/Css/Style.css'
import { useEffect, useState } from "react";
import Utils from "../utils/Utils";
import imgJdihn from "../assets/Img/jdihn.png";
import imgBnpp from "../assets/Img/logo_bnpp.png";

function NavHeader(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");
    const [show1, setShow1] = useState();
    const [show2, setShow2] = useState();
    const [show3, setShow3] = useState();
    const [show4, setShow4] = useState();
    const [fix, setFix] = useState(false);
    const dispatch = useDispatch();

    const toTopFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    //
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "id",
                autoDisplay: false,
                includedLanguages: 'id,en,ms,ja,ko,zh-CN,zh-TW,fr,ru', // include this for selected languages
            },
            "google_translate_element"
        );
    };

    const createTranslate = () => {
        let addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;

    }

    useEffect(() => {

        // set language
        props.setLang(language[1])

        // set tentang kami 
        fetch(`${Utils.url}profile`)
            .then((response) => response.json())
            .then(json => {
                // console.log(json.data.visi_misi.deskripsi);
                props.setTentangKami({
                    desc: json.data.visi_misi.deskripsi,
                    visi: json.data.visi_misi.filepath,
                    statistik: json.data.statistik_pengunjung,
                    landasanHukum: json.data.landasan_hukum.deskripsi,
                    sop: json.data.sop.filepath,
                    anggota: json.data.anggota,
                    jenisPeraturan: json.data.jenis_peraturan,
                    singkatanJenis: json.data.singkatan_jenis,
                    statusPeraturan: json.data.status_peraturan,
                    profile: json.data.profile,
                    media: json.data.media_sosial,
                    struktur: json.data.struktur_organisasi,
                    tentangKami: json.data.tentang_kami[4].deskripsi
                })
            });

        //
        let el = document.getElementById("responsive-navbar-nav");
        el.classList.remove("show")
        toTopFunction();

        if (props.translate) {
            //
            let el1 = document.getElementById("google_translate_element");
            el1.innerHTML = "";
            if(el1.innerHTML == ""){
                createTranslate()
                dispatch({ type: 'SET_TRANSLATE', value: false })
            }
            // setTimeout(() => {
            //     createTranslate()
            //     dispatch({ type: 'SET_TRANSLATE', value: false })
            // }, 1000);
        }

    }, [location]);

    function setFixed() {
        if (window.scrollY >= 100) {
            setFix(true);
        } else {
            setFix(false);
        }
    }

    window.addEventListener("scroll", setFixed)


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navbar-top-header">
                <Container>
                    <Nav>
                        <Nav.Link>
                            <img src={imgJdihn} alt="" width={60} height={60} />
                        </Nav.Link>
                        <Nav.Link>
                            <img src={imgBnpp} alt="" width={60} height={60} />
                        </Nav.Link>
                        <Nav.Link>
                            <div className="brand-title">
                                <div>JDIH BADAN NASIONAL</div>
                                <div>PENGELOLA PERBATASAN RI</div>
                            </div>
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav>
                            <div id="google_translate_element"></div>
                        </Nav>
                        <Nav.Link className="top-link" as={Link} style={{ paddingRight: '0px' }} to={`/id`}>ID</Nav.Link>
                        <Nav.Link className="top-link" to="/id" style={{ paddingLeft: '0px', paddingRight: '0px' }}>/</Nav.Link>
                        <Nav.Link className="top-link" as={Link} style={{ paddingLeft: '0px' }} to={`/en`}>EN</Nav.Link>
                        <Nav.Link className="top-link" as={Link} to={`https://admin-jdih.bnpp.go.id/login`} target="blank">
                            Login
                            &nbsp;
                            <FontAwesomeIcon icon={faLock} className="fa-sm" />
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <Navbar collapseOnSelect expand="lg" className={`bg-theme shadow ${fix ? 'fixed-top' : ''} `}>
                <Container>
                    <Navbar.Brand className="nav-brand" style={{ fontWeight: '600', fontSize: '12pt' }}>JDIH BNPP RI</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="rounded-0" style={{ fontSize: '12pt', textShadow: '1px 1px #464646' }} />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                        <Nav>
                            <Nav.Link as={Link} to={`${props.lang}`} >{props.menu.beranda}</Nav.Link>
                            <NavDropdown
                                title={props.menu.informasiLain}
                                id="collapsible-nav-dropdown"
                                show={show3}
                                onMouseEnter={() => setShow3(true)}
                                onMouseLeave={() => setShow3(false)}
                            >
                                <NavDropdown.Item as={Link} to={`${props.lang}/TentangKami`} className="dropdown-item">Tentang Kami</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/VisiMisi`} className="dropdown-item">Visi Misi</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/StrukturOrganisasi`} className="dropdown-item">Struktur Organisasi</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Anggota`} className="dropdown-item">Stakeholder</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/LandasanHukum`} className="dropdown-item">Landasan Hukum</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Sop`} className="dropdown-item">SOP</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                title={props.menu.dokumenHukum}
                                id="collapsible-nav-dropdown"
                                show={show1}
                                onMouseEnter={() => setShow1(true)}
                                onMouseLeave={() => setShow1(false)}
                            >
                                <NavDropdown.Item as={Link} to={`${props.lang}/Peraturan`} className="dropdown-item">Peraturan</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Monografi`} className="dropdown-item">Monografi</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Artikel`} className="dropdown-item">Artikel</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Putusan`} className="dropdown-item">Putusan</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                title={props.menu.kegiatan}
                                id="collapsible-nav-dropdown"
                                show={show2}
                                onMouseEnter={() => setShow2(true)}
                                onMouseLeave={() => setShow2(false)}
                            >
                                <NavDropdown.Item as={Link} to={`${props.lang}/Berita`} className="dropdown-item">Berita</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Galery`} className="dropdown-item">Galery</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                title="Informasi Lain"
                                id="collapsible-nav-dropdown"
                                show={show4}
                                onMouseEnter={() => setShow4(true)}
                                onMouseLeave={() => setShow4(false)}
                            >
                                <NavDropdown.Item as={Link} to={`${props.lang}/Infografis`} className="dropdown-item">Infografis</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Videografis`} className="dropdown-item">Videografis</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/Glosarium`} className="dropdown-item">Glosarium</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`${props.lang}/JenisPeraturan`} className="dropdown-item">Matrik Produk Hukum</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={Link} to={`${props.lang}/Kontak`} >{props.menu.kontak}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

// map state to props
const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        menu: state.menu,
        translate: state.translate
    }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => {
    return {
        setLang: (lang) => dispatch(setLanguage(lang)),
        setTentangKami: (data) => dispatch(setTentangKami(data))
    };
}

// set language 
const setLanguage = (lang) => {
    return {
        type: "SET_LANG",
        lang: lang
    }
}

// set tentang kami
const setTentangKami = (data) => {
    return {
        type: "SET_TENTANGKAMI",
        visiMisi: data.desc,
        tentangKami: data.tentangKami,
        statistik: data.statistik,
        landasanHukum: data.landasanHukum,
        sop: data.sop,
        anggota: data.anggota,
        jenisPeraturan: data.jenisPeraturan,
        singkatanJenis: data.singkatanJenis,
        statusPeraturan: data.statusPeraturan,
        profile: data.profile,
        media: data.media,
        struktur: data.struktur,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);