import { Col, Container, Image, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import { connect } from "react-redux";
import Divider from "../../../components/Divider";
import Sotk from "../../../assets/Img/SOTK.jpg";
import Utils from "../../../utils/Utils";

function StrukturOrganisasi(props) {
    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Struktur Organisasi" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-start">
                            <div style={{ fontWeight: '600', color: '#464646' }}>
                                JDIH Badan Nasional Pengelola Perbatan RI
                            </div>
                            <Divider width={20} />
                        </Col>
                    </Row>
                    <Row className="align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-start">
                            <div>
                                {props.struktur.deskripsi}
                                {console.log(props.sop)}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <Image src={Sotk} style={{ marginBottom:'20px' }} className="shadow img-sotk" />
                            <br />
                            <a href={`${Utils.urlStorage}${props.sop}`} target="_blank">Download Disini</a>
                            {/* <object data={`${Utils.urlStorage}${props.sop}`}
                                style={{ width: '100%', height: '700px' }}>
                            </object> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        sop: state.sop,
        struktur: state.struktur
    }
}

export default connect(mapStateToProps)(StrukturOrganisasi);