import { Container, Image, Row } from "react-bootstrap";
import myImage from "../../assets/Img/404-page-not-found-monochromatic-32679.png"


function NotFound() {
    return (
        <>
            <Container>
                <Row>
                    <div className="w-100 text-center" style={{ paddingTop:'70px', paddingBottom:'70px' }}>
                        <Image src={myImage} style={{ height: '350px' }} />
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default NotFound;