import { useEffect, useState } from 'react';
import '../../assets/Css/Style.css';
import HomeAnggota from '../../components/HomeAnggota';
import HomeBanner from '../../components/HomeBanner';
import HomeBerita from '../../components/HomeBerita';
import HomeInfografis from '../../components/HomeInfografis';
import HomePeraturan from '../../components/HomePerarturan';
import HomeStatistik from '../../components/HomeStatistik';
import HomeSurvey from '../../components/HomeSurvey';
import HomeVideoGrafis from '../../components/HomeVideoGrafis';
import Utils from '../../utils/Utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';


function Home(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [statistik, setStatistik] = useState([]);
    const [berita, setBerita] = useState([]);
    const [peraturan, setPeraturan] = useState([]);

    const getData = () => {
        fetch(`${Utils.url}profile`)
            .then((response) => response.json())
            .then((json) => {
                setStatistik(json.data.statistik_peraturan)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getPeraturan = () => {
        fetch(`${Utils.url}peraturan`)
            .then((response) => response.json())
            .then((json) => {
                setPeraturan(json.data.terbaru)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBerita = () => {
        fetch(`${Utils.url}berita`)
            .then((response) => response.json())
            .then((json) => {
                setBerita(json.data.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData();
        getPeraturan();
        getBerita();
    }, [])

    const handleSearch = (data) => {
        dispatch({
            type: 'SET_SEARCH',
            data: data
        })

        navigate(`/in/Peraturan/Search`)
    }

    return (
        <>
            <HomeBanner
                handleSearch={handleSearch}
                jenisPeraturan={props.jenisPeraturan}
                singkatanJenis={props.singkatanJenis}
                statusPeraturan={props.statusPeraturan}
                profile={props.profile}
            />

            <HomePeraturan dataPeraturan={peraturan} />

            <HomeBerita dataBerita={berita} />

            <HomeInfografis />

            <HomeVideoGrafis />

            <HomeSurvey />

            <HomeStatistik dataStatistik={statistik} />

            <HomeAnggota />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        jenisPeraturan: state.jenisPeraturan,
        singkatanJenis: state.singkatanJenis,
        statusPeraturan: state.statusPeraturan,
        profile: state.profile
    }
}

export default connect(mapStateToProps)(Home);