import { Button, Col, Form, InputGroup, Row, useAccordionButton } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function BeritaSearch({ handleSearch, sessionSearch }) {
    const [searchInput, setSearchInput] = useState(JSON.stringify(sessionSearch) == "{}" ? "" : sessionSearch);

    const handleSubmit = (e) => {
        e.preventDefault()

        handleSearch(searchInput);
    }

    return (
        <div className="bg-dark-app pt-3 rounded shadow">
            <Form style={{ padding: '0 15px 0 15px' }} onSubmit={handleSubmit}>
                {/* <Form.Control type="email" placeholder="Search" /> */}
                <Row>
                    <Col>
                        <label className="text-white w-100 text-start">Masukan kata kunci pencarian berita</label>
                        <InputGroup style={{ paddingBottom: '15px' }}>
                            <Form.Control
                                className="input-search"
                                placeholder="Search"
                                aria-describedby="basic-addon2"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <Button className="btn-search" variant="outline-secondary" id="button-addon2">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
export default BeritaSearch;