import { Container } from "react-bootstrap";

function LacakDokumen() {
    return (
        <>
            <Container>
                <h1>Page Lacak Dokumen</h1>
            </Container>
        </>
    );
}

export default LacakDokumen;