import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Divider from "./Divider";
import { Link, useLocation } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

function HomePeraturan({ dataPeraturan }) {
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/")

    return (
        <section className="mt-5">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" delay={300} animateOnce={true}>
                <Container>
                    <Row>
                        <Col>
                            <h3>Peraturan Terbaru</h3>
                            <Divider width={20} />
                        </Col>
                        <Col className="text-end">
                            <Button as={Link} to={`/${language[1]}/Peraturan`} className="btn-theme hvr-float-shadow">Lihat Semua</Button>
                        </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-3 g-4 mb-3">
                        {
                            dataPeraturan.map((item, index) => (
                                <Col key={index}>
                                    <Card className="border-0">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={2}>
                                                    <div className="text-center rounded widget">
                                                        <div style={{ padding: '10px' }}>
                                                            <FontAwesomeIcon icon={faFilePdf} style={{ color: '#e6e6e6', fontSize: '20pt' }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={10}>
                                                    <div style={{ fontSize: '10.5pt' }}>
                                                        {/* <div style={{ fontWeight: '600' }}>{item.singkatan_jenis}</div> */}
                                                        {/* <div style={{ fontWeight: '600' }}>{item.jenis_peraturan} Nomor {item.nomor} Tahun {item.tahun_terbit}</div> */}
                                                        <div style={{ fontWeight: '600' }}>{item.judul2}</div>
                                                        <div style={{ marginTop: '10px', color: '#8D8D8D' }}>{item.judul}</div>
                                                        <Button as={Link} to={`/${language[1]}/Peraturan/Detail/${item.request_no}`} variant="outline-info" className="mt-3" style={{ fontSize: '10pt' }}>Detail</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

export default HomePeraturan;