import {
    Col,
    Container,
    Image,
    Row,
} from "react-bootstrap";
import '../assets/Css/Style.css';
import PeraturanSearch from "../pages/DokumenHukum/Peraturan/PeraturanSearch";

function HomeBanner({ handleSearch, jenisPeraturan, singkatanJenis, statusPeraturan, profile }) {

    console.log("profile data", profile);

    return (
        <div className="shadow" style={{ position: 'relative', height: '500px' }}>
            <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                {/* <Image style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://jdih.maritim.go.id/cfind/source/thumb/images/banner/cover_w1600_h430_tw4097_th1099_x1488_y17_ais-forum3.png" /> */}
                {/* <Image style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://admin-jdih.bnpp.go.id/storage/gambar/banner_lampiran.jpg" /> */}
                <Image style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={`https://admin-jdih.bnpp.go.id/storage/gambar/${profile.banner}`} />
            </div>
            {/* <div style={{ background: '#000', height: '100%', width: '100%', opacity: '0.7', position: 'absolute' }}></div> */}
            <div className="bgHolder"></div>
            <section style={{ position: 'relative' }}>
                <Container className="h-100">
                    <Row className="align-item-center justify-content-center h-100">
                        <Col lg={12} className="text-center mt-5">
                            <h3 className="text-white mt-5" style={{ fontWeight: '600', textShadow: '0px 1px #464646' }}>Selamat Datang di JDIH Badan Nasional Pengelola Perbatasan RI</h3>
                            <p className="text-white mt-2" style={{ fontWeight: '400', fontSize: '12.5pt', textShadow: '0px 1px #464646' }}>Silahkan lakukan pencarian dokumen yang kamu inginkan</p>
                        </Col>
                    </Row>
                    <Row className="align-item-center justify-content-center h-100">
                        <Col lg={8} className="text-center">
                            {/* <h3 className="text-white mt-5" style={{ fontWeight: '600', textShadow: '0px 1px #464646' }}>Selamat Datang di JDIH Badan Nasional Pengelola Perbatasan RI</h3>
                            <p className="text-white mt-2" style={{ fontWeight: '400', fontSize: '12.5pt', textShadow: '0px 1px #464646' }}>Silahkan lakukan pencarian dokumen yang kamu inginkan</p> */}
                            <PeraturanSearch
                                handleSearch={handleSearch}
                                jenisPer={jenisPeraturan}
                                singkatanJen={singkatanJenis}
                                statusPer={statusPeraturan}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}

export default HomeBanner;