import { Card, Col, Container, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from "react";
import Utils from "../../utils/Utils";
import FilterComponent from "../../components/FilterComponent";

function JenisPeraturan() {
    const [datas, setDatas] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const columns = [
        {
            name: 'No',
            width: '8%',
            wrap: true,
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Jenis Peraturan',
            wrap: true,
            // selector: row => row.jenis_peraturan,
            cell: (row) => {
                return <a href={`/id/Peraturan`}>{row.jenis_peraturan}</a>
            },
            sortable: true,
        },
        {
            name: 'Total',
            wrap: true,
            width: '10%',
            // selector: row => row.total,
            cell: (row, index) => {
                if (row.total == 1) {
                    return <span style={{ backgroundColor: '#1976D2', padding: '2px 10px 2px 10px', borderRadius: '50%', color: '#fff' }}>{row.total}</span>
                }

                if (row.total > 10) {
                    return <span style={{ backgroundColor: '#1976D2', padding: '5px 6px 5px 6px', borderRadius: '50%', color: '#fff' }}>{row.total}</span>
                }

                if (row.total > 1 && row.total < 10) {
                    return <span style={{ backgroundColor: '#1976D2', padding: '2px 8px 2px 8px', borderRadius: '50%', color: '#fff' }}>{row.total}</span>
                }

                if (row.total === 0) {
                    return <span style={{ backgroundColor: '#1976D2', padding: '2px 8px 2px 8px', borderRadius: '50%', color: '#fff' }}>{row.total}</span>
                }

            },
            sortable: true,
        },
    ];

    const filteredItems = datas.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const getData = () => {
        let formData = new FormData()
        formData.append("process", "get")

        fetch(`${Utils.urlDev}JenisPeraturan`, {
            method: "post",
            body: formData
        })
            .then((response) => response.json())
            .then(json => {
                console.log(json);
                setDatas(json.data)
            })
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <section style={{ background: '#dedede' }}>
            <HeadDokumenHukum title="Matrik Produk Hukum" />
            <Container>
                <Row className="mt-5 align-item-center justify-content-center h-100">
                    <Col lg={10} className="text-center">
                        <Card className="mb-5">
                            <Card.Body className="text-start">
                                <DataTable
                                    title="Matrik Produk Hukum"
                                    columns={columns}
                                    data={filteredItems}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    subHeader
                                    subHeaderComponent={subHeaderComponent}
                                    persistTableHead
                                    striped
                                    responsive
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default JenisPeraturan;