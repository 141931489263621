import { Card, Col, Container, Image, Row } from "react-bootstrap";
import HeadDokumenHukum from "../../components/HeadDokumenHukum";
import { Link, useLocation, useParams } from "react-router-dom";
import Utils from "../../utils/Utils";
import { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function VideoGrafisDetail() {
    let { id } = useParams();
    const [datas, setDatas] = useState();
    const [state, setState] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;
    const language = pathname.split("/");

    const getData = () => {
        fetch(`${Utils.urlDev}VideoGrafis/Detail/${id}`)
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                setDatas(json.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData();
    }, [id])

    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="VideoGrafis Detail" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={7} className="text-center">
                            {datas && (
                                <>
                                    <div className="text-start" style={{ color: '#464646' }}>{datas.created_date}</div>
                                    <h3 className="text-start" style={{ fontWeight: '600', color: '#464646' }}>{datas.judul}</h3>
                                    <hr/>
                                </>
                            )}

                            {datas && (
                                datas.video.map((item, index) => (
                                    <div className="ratio ratio-16x9" key={index}>
                                        {/* <iframe src={`${Utils.urlStorageLocal}${item.video_path}`} title={item.judul}></iframe> */}
                                        <video controls>
                                            <source src={`${Utils.urlStorageLocal}${item.video_path}`} />
                                        </video>
                                    </div>
                                ))
                            )}

                            {datas && (
                                <Card className="shadow">
                                    <Card.Body>
                                        <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>
                                            {datas.isi}
                                        </pre>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                    </Row>


                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <hr />
                            <h3 className="mb-4">VideoGrafis Lainnya</h3>
                            <Row className="row-cols-1 row-cols-md-3 g-4 mb-3">
                                {datas && (
                                    datas.video_grafis_lain.map((item, index) => (
                                        <Col key={index}>
                                            <Link className="text-decoration-none" to={`/in/VideoGrafis/Detail/${item.id}`}>
                                                <Card className="shadow text-start" style={{ width: '100%' }}>
                                                    <div className="ratio ratio-16x9">
                                                        {/* <iframe src={`${Utils.urlStorageLocal}${item.video_path}`} title={item.judul}></iframe> */}
                                                        <video controls>
                                                            <source src={`${Utils.urlStorageLocal}${item.video_path}`} />
                                                        </video>
                                                    </div>
                                                    <Card.Body style={{ height: '150px' }}>
                                                        <Card.Text style={{ fontWeight: '600', color: '#464646' }}>
                                                            {item.judul}
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <small>{item.created_date}</small>
                                                    </Card.Footer>
                                                </Card>
                                            </Link>
                                        </Col>
                                    ))
                                )}

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default VideoGrafisDetail;