import { Accordion, Button, Card, Col, Container, Form, InputGroup, Pagination, Row, useAccordionButton } from "react-bootstrap";
import { faChevronCircleDown, faDownload, faEye, faFilePdf, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function PeraturanSearch({ handleSearch, jenisPer, singkatanJen, statusPer }) {
    const [jenisPeraturan, setJenisPeraturan] = useState("");
    const [keys, setKeys] = useState("");
    const [nomor, setNomor] = useState("");
    const [singkatanJenis, setSingkatanJenis] = useState("");
    const [statusPeraturan, setStatusPeraturan] = useState("");
    const [tahun, setTahun] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault()

        let data = {
            keys: keys,
            nomor: nomor,
            singkatan_jenis: singkatanJenis,
            status_peraturan: statusPeraturan,
            jenis_peraturan: jenisPeraturan,
            tahun: tahun, 
            nomor: nomor
        }

        handleSearch(data)
    }

    return (
        <div className="bg-dark-app pt-3 rounded shadow">
            <Form onSubmit={handleSubmit} style={{ padding: '0 15px 0 15px' }}>
                {/* <Form.Control type="email" placeholder="Search" /> */}
                {/* <label className="text-white w-100 text-start">Masukan kata kunci pencarian dokumen</label> */}
                <InputGroup>
                    <Form.Control
                        className="input-search"
                        placeholder="Search"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setKeys(e.target.value)}
                    />
                    <Button className="btn-search" variant="outline-secondary" id="button-addon2">
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </InputGroup>
            </Form>
            {/* accordion  */}
            <Accordion>
                <Card className="bg-dark-app border-0">
                    <Card.Header className="border-0 text-center">
                        <CustomToggle eventKey="0">Pencarian Detail &nbsp; <FontAwesomeIcon icon={faChevronCircleDown} /></CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="text-white">
                            <Row>
                                <Col sm={2}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Nomor"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setNomor(e.target.value)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Tahun"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setTahun(e.target.value)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Form.Select onChange={(e) => setJenisPeraturan(e.target.value)}>
                                        <option value={""}>Jenis Peraturan</option>
                                        {
                                            jenisPer && (
                                                jenisPer.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                                <Col sm={2}>
                                    <Form.Select onChange={(e) => setSingkatanJenis(e.target.value)}>
                                        <option value={""}>Singkatan Jenis</option>
                                        {
                                            singkatanJen && (
                                                singkatanJen.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                                <Col sm={2}>
                                    <Form.Select onChange={(e) => setStatusPeraturan(e.target.value)}>
                                        <option value={""}>Status Peraturan</option>
                                        {
                                            statusPer && (
                                                statusPer.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                                <Col sm={2}>
                                    <Button className="w-100" variant="primary" onClick={handleSubmit}>Cari</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <Button
            className="text-white"
            onClick={decoratedOnClick}
            variant="default"
            style={{ fontSize: '10pt' }}>
            {children}
        </Button>
    );
}

export default PeraturanSearch;