import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NavHeader from './components/NavHeader';
import Home from './pages/Home/Home'
import Kontak from './pages/Kontak/Kontak';
import LacakDokumen from './pages/LacakDokumen/LacakDokumen';
import PeraturanHome from './pages/DokumenHukum/Peraturan/PeraturanHome';
import MonografiHome from './pages/DokumenHukum/Monografi/MonografiHome';
import ArtikelHome from './pages/DokumenHukum/Artikel/ArtikelHome';
import PutusanHome from './pages/DokumenHukum/Putusan/PutusanHome';
import Footer from './components/Footer';
import PeraturanDetail from './pages/DokumenHukum/Peraturan/PeraturanDetail';
import InfografisDetail from './pages/Infografis/InfografisDetail';
import InfografisHome from './pages/Infografis/InfografisHome';
import NotFound from './pages/NotFound/NotFound';
import MonografiDetail from './pages/DokumenHukum/Monografi/MonografiDetail';
import ArtikelDetail from './pages/DokumenHukum/Artikel/ArtikelDetail';
import { Backdrop, CircularProgress } from '@mui/material';
import BeritaHome from './pages/Kegiatan/Berita/BeritaHome';
import BeritaDetail from './pages/Kegiatan/Berita/BeritaDetail';
import TentangKami from './pages/InformasiLain/TentangKami/TentangKami';
import VisiMisi from './pages/InformasiLain/VisiMisi/VisiMisi';
import LandasanHukum from './pages/InformasiLain/LandasanHukum/LandasanHukum';
import StrukturOrganisasi from './pages/InformasiLain/StrukturOrganisasi/StrukturOrganisasi';
import Anggota from './pages/InformasiLain/Anggota/Anggota';
import PeraturanSearchResult from './pages/DokumenHukum/Peraturan/PeraturanSearchResult';
import Sop from './pages/InformasiLain/Sop/Sop';
import VideoGrafisHome from './pages/VideoGrafis/VideoGrafisHome';
import VideoGrafisDetail from './pages/VideoGrafis/VideoGrafisDetail';
import Glosarium from './pages/Glosarium/Glosarium';
import Galery from "./pages/Kegiatan/Galery/Galery"
import JenisPeraturan from './pages/JenisPeraturan/JenisPeraturan';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const language = pathname.split("/")
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (language[1].length == 0) {
      navigate('/id')
    }

    setActive(true)
    setTimeout(() => {
      setActive(false)
    }, 1500);

  }, [location])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={active}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavHeader />
      <Routes>
        <Route path='/:lang' element={<Home />} />

        <Route path='/:lang/Peraturan' element={<PeraturanHome />} />
        <Route path='/:lang/Peraturan/Search' element={<PeraturanSearchResult />} />
        <Route path='/:lang/Peraturan/Detail/:docno' element={<PeraturanDetail />} />

        <Route path='/:lang/Monografi' element={<MonografiHome />} />
        <Route path='/:lang/Monografi/Detail/:docno' element={<MonografiDetail />} />

        <Route path='/:lang/Artikel' element={<ArtikelHome />} />
        <Route path='/:lang/Artikel/Detail/:docno' element={<ArtikelDetail />} />

        <Route path='/:lang/Putusan' element={<PutusanHome />} />
        <Route path='/:lang/Putusan/Detail/:docno' element={<PutusanHome />} />

        <Route path='/:lang/Kontak' element={<Kontak />} />
        <Route path='/:lang/LacakDokumen' element={<LacakDokumen />} />

        <Route path='/:lang/Infografis' element={<InfografisHome />} />
        <Route path='/:lang/Infografis/Detail/:id' element={<InfografisDetail />} />

        <Route path='/:lang/VideoGrafis' element={<VideoGrafisHome />} />
        <Route path='/:lang/VideoGrafis/Detail/:id' element={<VideoGrafisDetail />} />

        <Route path='/:lang/Berita' element={<BeritaHome />} />
        <Route path='/:lang/Berita/Detail/:id' element={<BeritaDetail />} />

        <Route path='/:lang/TentangKami' element={<TentangKami />} />
        <Route path='/:lang/Kontak' element={<InfografisDetail />} />
        <Route path='/:lang/VisiMisi' element={<VisiMisi />} />
        <Route path='/:lang/LandasanHukum' element={<LandasanHukum />} />
        <Route path='/:lang/StrukturOrganisasi' element={<StrukturOrganisasi />} />
        <Route path='/:lang/Anggota' element={<Anggota />} />
        <Route path='/:lang/Sop' element={<Sop />} />
        <Route path='/:lang/Glosarium' element={<Glosarium />} />

        <Route path='/:lang/Galery' element={<Galery />}/>
        <Route path='/:lang/JenisPeraturan' element={<JenisPeraturan />}/>

        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
