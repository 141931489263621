import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import Divider from "../../../components/Divider";

function TentangKami(props) {
    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Tentang Kami" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <Card>
                                <Card.Body className="text-start">
                                    <div style={{ fontWeight: '600', color: '#464646' }}>
                                        JDIH Badan Nasional Pengelola Perbatan RI
                                    </div>
                                    <Divider width={20} />
                                    {props.tentangKami && (
                                        // <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#464646' }}>{props.tentangKami}</pre>
                                        <div dangerouslySetInnerHTML={{ __html: props.tentangKami }} />
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tentangKami: state.tentangKami
    }
}

export default connect(mapStateToProps)(TentangKami);