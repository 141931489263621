import { Skeleton } from "@mui/material";
import { Card } from "react-bootstrap";

function CardItemSkelton() {
    return (
        <Card className="shadow-sm w-100 mb-3">
            <Card.Header>
                <Skeleton variant="rounded" width={100} height={30} />
            </Card.Header>
            <Card.Body className="h-100 text-start">
                <div className="d-flex bd-highlight mb-3">
                    <div className="pe-3 bd-highlight widget-head">
                        <Skeleton variant="rounded" width={50} height={50} />
                    </div>
                    <div className="w-100 h-100">
                        <div className="mb-2">
                            <Skeleton variant="rounded" width={'70%'} height={20} />
                        </div>
                        <div className="mb-2">
                            <Skeleton variant="rounded" width={'70%'} height={20} />
                        </div>
                        <div>
                            <Skeleton variant="rounded" width={'70%'} height={20} />
                        </div>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <Skeleton variant="rounded" width={'100%'} height={30} />
            </Card.Footer>
        </Card>
    );
}

export default CardItemSkelton;