import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";

function HeadDokumenHukum(props) {
    const profile = useSelector((state) => state.profile);

    console.log(profile);

    return (
        <section className="shadow" style={{ position: 'relative', height: '200px', width: '100%' }}>
            <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                {/* <Image style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://plus.unsplash.com/premium_photo-1669317566483-d3327adeda10?auto=format&fit=crop&q=80&w=1631&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" /> */}
                {/* <Image style={{ objectFit: 'cover', width: '100%', height: '100%' }} src="https://admin-jdih.bnpp.go.id/storage/gambar/banner_menu_banner_menu.jpg" /> */}
                <Image style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={`https://admin-jdih.bnpp.go.id/${profile.banner_menu_path}`} />
            </div>
            <div style={{ position: 'absolute', height: '100%', width: '100%', background: '#000', opacity: '0.7' }}></div>
            <h1 style={{ fontSize: '27pt', top: '37%', textAlign: 'center', width: '100%', fontWeight: '600', position: 'absolute', color: '#fff' }}>
                {props.title}
            </h1>
        </section>
    );
}

export default HeadDokumenHukum;