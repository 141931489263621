import { useEffect, useState } from "react";
import { Col, Container, Image, Nav, Row } from "react-bootstrap";
import Utils from "../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarker, faPhone, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faInstagramSquare, faTiktok, faTwitterSquare, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import ImgBnpp from "../assets/Img/logo_bnpp.png";

function Footer(props) {
    // const [profile, setProfile] = useState([])

    // const getData = () => {
    //     fetch(`${Utils.url}profile`)
    //         .then((response) => response.json())
    //         .then((json) => {
    //             setProfile(json.data.profile)
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }

    // useEffect(() => {
    //     getData()
    // }, [])

    const handleMedia = (uri) => {
        window.open(uri);
    }

    return (
        <section className="pt-5 pb-5 footer">
            <Container>
                <Row>
                    <Col sm={3}>
                        <Row>
                            <Nav className="d-flex footer-name" style={{ paddingLeft: '1.5rem' }}>
                                <Nav.Item>
                                    {/* <Image src="https://jdih.bnpp.go.id/img/logo_bnpp.8903d613.png" style={{ height: '55px' }} /> */}
                                    <Image src={ImgBnpp} style={{ height: '55px' }} />
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link>
                                        Badan Nasional <br /> Pegelola Perbatan RI
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Row>
                        <Row>
                            <div className="footer-kontak">
                                <FontAwesomeIcon icon={faMapMarker} />
                                &nbsp;
                                {props.profile.alamat_lembaga}
                            </div>
                            <div className="footer-kontak">
                                <FontAwesomeIcon icon={faEnvelope} />
                                &nbsp;
                                &nbsp;
                                {props.profile.email_lembaga}
                            </div>
                            <div className="footer-kontak">
                                <FontAwesomeIcon icon={faPhone} />
                                &nbsp;
                                &nbsp;
                                {props.profile.telp_lembaga}
                            </div>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Row className="text-center">
                            <span className="d-flex justify-content-center footer-judul">Statistik Pengunjung</span>
                            <span className="d-flex justify-content-center">
                                <hr style={{ width: '20%', border: '1px solid #c2c2c2' }} />
                            </span>
                        </Row>
                        <Row>
                            <div className="footer-kontak text-center">
                                Hari ini : {props.statistik.hari_ini}
                            </div>
                            <div className="footer-kontak text-center">
                                Bulan ini : {props.statistik.bulan_ini}
                            </div>
                            <div className="footer-kontak text-center">
                                Tahun ini : {props.statistik.tahun_ini}
                            </div>
                            <div className="footer-kontak text-center">
                                Total : {props.statistik.total}
                            </div>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Row className="text-center">
                            <span className="d-flex justify-content-center footer-judul">Media Sosial</span>
                            <span className="d-flex justify-content-center">
                                <hr style={{ width: '20%', border: '1px solid #c2c2c2' }} />
                            </span>
                        </Row>
                        <Row>
                            <Nav className="nav justify-content-center">
                                <Nav.Item>
                                    <Nav.Link onClick={() => handleMedia(props.media[0].url)}>
                                        <FontAwesomeIcon icon={faInstagramSquare} className="fa-2x" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => handleMedia(props.media[1].url)}>
                                        <FontAwesomeIcon icon={faFacebookSquare} className="fa-2x" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => handleMedia(props.media[2].url)}>
                                        <FontAwesomeIcon icon={faYoutubeSquare} className="fa-2x" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => handleMedia(props.media[3].url)}>
                                        <FontAwesomeIcon icon={faTwitterSquare} className="fa-2x" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => handleMedia(props.media[4].url)}>
                                        <FontAwesomeIcon icon={faTiktok} className="fa-2x" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Row>
                        <Row>
                            <span className="d-flex justify-content-center footer-judul mt-3">Unduh Aplikasi</span>
                            <div className="d-flex justify-content-center">
                                <Link to={`https://play.google.com/store/apps/details?id=com.jdih.bnpp&pli=1`} target="_blank">
                                    <Image src="https://jdih.kemenkeu.go.id/assets/images/badge-google-play.webp" style={{ height: '70px' }} />
                                </Link>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-center">
                                <Link to={`https://apps.apple.com/us/app/jdih-bnpp/id6477374366`} target="_blank">
                                    <Image src="https://jdih.kemenkeu.go.id/assets/images/badge-app-store.webp" style={{ height: '70px' }} />
                                </Link>
                            </div>
                        </Row>
                    </Col>
                    <Col sm={3}>
                        <Row className="text-center">
                            <span className="d-flex justify-content-center footer-judul">Scan QR Code</span>
                            <span className="d-flex justify-content-center">
                                <hr style={{ width: '20%', border: '1px solid #c2c2c2' }} />
                            </span>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-center">
                                <div style={{ background: '#fff', padding: '5px' }}>
                                    <QRCode value="https://jdih.bnpp.go.id" size={150} />
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        statistik: state.statistik,
        profile: state.profile,
        media: state.media
    }
}

export default connect(mapStateToProps)(Footer);